/**
 * 时间日期相关操作
 */

/**
 * 时间格式化
 * 将 2018-09-23T11:54:16.000+0000 格式化成 2018/09/23 11:54:16
 * @param datetime 国际化日期格式
 */
export function format(datetime) {
  return formatWithSeperator(datetime, '/', ':')
}

/**
 * 时间格式化
 * 将 2018-09-23T11:54:16.000+0000 格式化成类似 2018/09/23 11:54:16
 * 可以指定日期和时间分隔符
 * @param type (date：只保留日期部分,time：日期+时间)
 * @param datetime 国际化日期格式
 */
export function formatWithSeperator(type, datetime, dateSeprator, timeSeprator) {
  if (datetime != null) {
    const dateMat = new Date(datetime)
    const year = dateMat.getFullYear()
    const month = dateMat.getMonth() + 1
    const day = dateMat.getDate()
    const hh = dateMat.getHours()
    const mm = dateMat.getMinutes()
    const ss = dateMat.getSeconds()
    const timeFormat = type === 'date' ? year + dateSeprator + month + dateSeprator + day : year + dateSeprator + month + dateSeprator + day + ' ' + hh + timeSeprator + mm + timeSeprator + ss
    return timeFormat
  }
}

/**
 * 获取秒级时间戳
 */
export function timestamp() {
  var timestamp = Date.parse(new Date()).toString()
  // timestamp = timestamp.substr(0, 10)
  return timestamp
}

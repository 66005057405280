<template>
    <div class="container" >
        <section class="content" :style="isShow?'height:450px':'height:150px;'">
            <div class="content-top">
                <div class="arrow">
                    <img @click="doShow" src="/imgs/shangla@2x.png" :style="isShow?'transform:rotate(180deg);':'transform:rotate(0);'"/>
                </div>
                <div class="MonitorplanNo">
                    <h4 >运输单号：{{list[0]?list[0].MonitorplanNo:''}}</h4>
                    <span class="type">{{list[0]?list[0].MonitorPlanType:''}}</span>
                </div>
                <div class="">
                    <img src="/imgs/chuangjianren@2x.png" alt="">
                    <span style="white-space: nowrap;">联系人：</span>
                    {{list[0]?list[0].PhoneNo:''}} {{list[0]?list[0].Creater:''}}
                </div>
                <div class="">
                    <img src="/imgs/shijian.png" alt="">
                    <span style="white-space: nowrap;">出发时间：</span>
                    {{list[0]?list[0].StartTime:''}}
                </div>
                <div>
                    <img src="/imgs/dizhi.png" alt="">
                    <span style="white-space: nowrap;">出发地：</span>
                    <div style="width:250px;">{{list[0]?list[0].StartPlace:''}}</div>
                </div>
            </div>
            <div v-if="isShow" class="content-bot">
                <div v-for="(item,idx) of list" :key="idx">
                    <div class="addr">
                        <div><span style="color:#888888;">{{item.Destination==item.StartPlace?'终点':'途径地'}}：</span><div class="palce">{{item.Destination}}</div></div>
                        <div style="color:#3591F0;"><span style="color:#888888;">状态：</span>{{item.Status==1?'运输中':item.Status==2?'已取消':item.IsChecked?'已交接':'已到达'}}</div>
                    </div>
                    <div class="list">
                        <div class="item" style="color:#B2B2B2;">
                            <div></div>
                            <div>运输设备</div>
                            <div>温度</div>
                            <div>物品清单</div>
                        </div>
                        <div class="item" v-for="(box,boxIdx) of item.boxlist" :key="boxIdx">
                            <div>{{boxIdx+1}}</div>
                            <div>{{box.boxName}}</div>
                            <div>{{box.temp?box.temp:'-'}}</div>
                            <div>{{box.listName?box.listName:'-'}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- <div class="test">
            <span>测试</span>
            <el-input-number v-model="num" controls-position="right" @change="handleChange" :min="0" :max="10" size="mini"></el-input-number>
        </div> -->
        <div id="map" ></div> 
        
    </div>
</template>
<script>
/* eslint-disable */
import {
    getMonitorPlanFindByBoxNoWithPCweb,
    getFindByNoToChatWithPCweb,
    getBaiduMapTrackWithPCweb,
    getJSON
} from "@/utils/api";
var WSCoordinate = require('../../utils/WSCoordinate')  
import { Decrypt, Encrypt } from '@/utils/encrypt'
import { timestamp } from '@/utils/datetime'

export default {
    name:'index',
    components:{},
    data () {
        return {
            isShow:false,
            db:'',
            id:'',
            code:'',

            type:'',
            list:[],

            markers:[],
            polylines:[],

            showContinue:false,

            num:0,

            islabel:false,

        }
    },
    methods:{
        // 展示更多
        doShow(){
            this.isShow=!this.isShow
        },
        // 切换百度或高德
        handleChange(e){
            if(this.timer){
                clearInterval(this.timer)
                this.timer=null
            }
            this.getTestData()
        },
        // 测试数据
        getTestData(){
            const that = this
            this.map.clearOverlays()
            this.map.removeOverlay(this.polyline)
            getJSON(this.num).then(res=>{
                let arr=[]
                let arr2=[]
                const GpsList = res.data
                GpsList.map((item,idx)=>{
                    let location1=WSCoordinate.transformFromWGSToGCJ(Number(item[1]),Number(item[0]));
                    let location2=WSCoordinate.transformFromGCJToBaidu(location1.latitude,location1.longitude);
                    arr2.push({"latitude":location2.latitude,"longitude":location2.longitude})

                    let t = item[4].substring(0,19);    
                    t = t.replace(/-/g,'/'); //必须把日期'-'转为'/'
                    let timestamp = new Date(t).getTime();
                    timestamp = timestamp.toString().substr(0,10)
                    const item3 = {
                        "coord_type_input":"bd09ll",
                        "loc_time":timestamp,
                        "latitude":location2.latitude,
                        "longitude":location2.longitude
                    }
                    arr.push(item3)
                })

                const position = new BMap.Point(GpsList[0][0],GpsList[0][1]); 
                const level = that.map.getZoom()
                that.map.centerAndZoom(position, 13);
                that.rectifying(arr)
            })
        },
        // 真实数据
        getData() {
            const that = this
            this.map.clearOverlays()
            this.map.removeOverlay(this.polyline)
            this.islabel=false
            getMonitorPlanFindByBoxNoWithPCweb(this.db,this.id).then(res=>{
                const data=res.data
                if (data.code == 1) {
                    const list = data.data
                    this.list=list
                    this.setMapData(list)
                }else{
                    const position = new BMap.Point(114.063977,22.55887); 
                    that.map.centerAndZoom(position, 3);
                }
            }).catch(err=>{
                console.log('err',err)
            })
            // if(this.type=='device'){
            //     getMonitorPlanFindByBoxNoWithPCweb(this.db,this.id).then(res=>{
            //         const data=res.data
            //         if (data.code == 1) {
            //             const list = data.data
            //             this.list=list
            //             this.setMapData(list)
            //         }else{
            //             const position = new BMap.Point(114.063977,22.55887); 
            //             that.map.centerAndZoom(position, 3);
            //         }
            //     }).catch(err=>{
            //         console.log('err',err)
            //     })
            // }else{
            //     getFindByNoToChatWithPCweb(this.db,this.id).then(res=>{
            //         const data=res.data
            //         if (data.code == 1) {
            //             const list = data.data
            //             this.list=list
            //             this.setMapData(list)
            //         }else{
            //             const position = new BMap.Point(114.063977,22.55887); 
            //             that.map.centerAndZoom(position, 3);
            //         }
            //     }).catch(err=>{
            //         console.log('err',err)
            //     })
            // }  
        },
        setMapData(list){
            const that = this
            let GpsList=[]
            let longitude = ''
            let latitude = ''
            let markers=[]

            list.map((item, idx) => {
                let arr = item.BoxList.split("|")
                let boxlist = []
                let marker_location={longitude:item.Longitude,latitude:item.Latitude}

                var myIcon = new BMap.Icon(item.Destination==item.StartPlace?'/imgs/loc_1.png':'/imgs/loc_4.png', new BMap.Size(32, 32));  
                myIcon.setImageSize(new BMap.Size(32,32));//设置图标大小
                
                var point = new BMap.Point(marker_location.longitude, marker_location.latitude);   
                // 创建标注对象并添加到地图  
                var marker = new BMap.Marker(point,{icon:myIcon});  
                markers.push(marker)

                var content = `<div class="info ${'info'+idx}" title="${item.Destination}" style="${item.Destination==item.StartPlace?'background:#F73C3C;':'background:#F73C3C;'}">${item.Destination}</div>`;
                var label = new BMap.Label(content, {       // 创建文本标注
                    position: point,
                    offset: new BMap.Size(-35, -40)
                })  
                label.setStyle({                              // 设置label的样式
                    color: '#fff',
                    background:'transparent',
                    padding:'0',
                    border: 'none'
                })
                
                markers.push(label)

                if(item.GpsList){
                    GpsList=item.GpsList
                    GpsList = GpsList.replace(/{/g,'[')
                    GpsList = GpsList.replace(/}/g,']')
                    GpsList =JSON.parse(GpsList)
                }

                if (item.BoxLongitude && item.BoxLatitude) {
                    let Box_location1=WSCoordinate.transformFromWGSToGCJ(item.BoxLatitude,item.BoxLongitude);
                    let Box_location=WSCoordinate.transformFromGCJToBaidu(Box_location1.latitude,Box_location1.longitude);
                    longitude = Box_location.longitude
                    latitude = Box_location.latitude
                }

                arr.map(item2 => {
                    if (item2 != "") {
                        let arr2 = item2.split("+")
                        let param = {
                            boxName: arr2[0],
                            temp: arr2[2],
                            listName: arr2[1]
                        }
                        boxlist.push(param)
                    }
                })
                item.boxlist = boxlist
            })

            if(list[0]){
                const item=list[0]
                var myIcon = new BMap.Icon('/imgs/loc_2.png', new BMap.Size(32, 32));  
                myIcon.setImageSize(new BMap.Size(32,32));//设置图标大小
                
                var point = new BMap.Point(item.StartLongitude, item.StartLatitude);   
                // 创建标注对象并添加到地图  
                var marker = new BMap.Marker(point,{icon:myIcon});  
                markers.push(marker)
                var content = `<div class="info title="${item.StartPlace}" style="background:#3A83F8;">${item.StartPlace}</div>`;
                var label = new BMap.Label(content, {       // 创建文本标注
                    position: point,
                    offset: new BMap.Size(-35, -40)
                })  
                label.setStyle({                              // 设置label的样式
                    color: '#fff',
                    background:'transparent',
                    padding:'0',
                    border: 'none'
                })
                markers.push(label)
            }
            // 运输箱
            if(latitude&&longitude){
                var carIcon = new BMap.Icon("/imgs/loc_3.png", new BMap.Size(32, 32));  
                carIcon.setImageSize(new BMap.Size(32,32));//设置图标大小
                var carPoint = new BMap.Point(longitude, latitude);
                var carMarker = new BMap.Marker(carPoint,{icon:carIcon});   
                markers.push(carMarker)

                that.latitude=latitude
                that.longitude=longitude
                const level = that.map.getZoom()
                that.map.centerAndZoom(new BMap.Point(longitude,latitude), level);
                
                const box={
                    addr:'',
                    time:'',
                    temp:'',
                }
                var myGeo = new BMap.Geocoder({extensions_town: true});
                myGeo.getLocation(new BMap.Point(longitude,latitude), function(result){  
                    if (result){      
                            box.addr = result.addressComponents.district + result.addressComponents.street + result.addressComponents.streetNumber
                            box.time = list[0].ArrivalTime
                            box.temp = ''
                    }      
                });
                that.label = new BMap.Label('', {
                    offset: new BMap.Size(-14, -95),
                });

                // carMarker.addEventListener("click", (e) => {
                //     if(that.islabel){
                //         that.islabel=!that.islabel
                //         that.map.removeOverlay(that.label);
                //     }else{
                //         that.islabel=!that.islabel
                //         that.label.setStyle({
                //             width: "auto",
                //             minwidth: "100px",
                //             textAlign: "center",
                //             fontSize: "12px",
                //             padding: "0",
                //             fontWeight: "400",
                //             color: "#000",
                //             border: "none",
                //             background:"transparent",
                //         });
                //         that.label.setContent(`
                //                 <div style="">
                //                     <div style="background:#fff;border:1px solid #3A83F8;padding:10px;border-radius:11px;">
                //                         <div style="display:flex;align-items:center; margin-bottom:5px;">
                //                             <img src="/imgs/wendu.png" alt="" style="width:15px;height:15px;">
                //                             <span style="color:#888888">温度：</span>
                //                             <span>${box.temp}</span>
                //                         </div>
                //                         <div style="display:flex;align-items:center; margin-bottom:5px;">
                //                             <img src="/imgs/shijian.png" alt="" style="width:15px;height:15px;">
                //                             <span style="color:#888888">更新时间：</span>
                //                             <span>${box.time}</span>
                //                         </div>
                //                         <div style="display:flex;align-items:center;">
                //                             <img src="/imgs/dizhi.png" alt="" style="width:15px;height:15px;">
                //                             <span style="color:#888888">地址：</span>
                //                             <span>${box.addr}</span>
                //                         </div>
                //                     </div>
                //                     <div style="background:#fff;width:20px;height:20px;border-left:1px solid #3A83F8;border-bottom:1px solid #3A83F8;position:absolute;bottom:-10px;left:20px;transform: rotate(-45deg);"></div>
                //                 </div>
                //             `);
                //         carMarker.setLabel(that.label);
                //     }
                // });
            }else{
                if(list.length>0){
                    const position = new BMap.Point(list[0].StartLongitude,list[0].StartLatitude);  
                    const level = that.map.getZoom()
                    that.map.centerAndZoom(position, level);
                }
            }
            
            
            // 覆盖物
            for(let v of markers){
                that.map.addOverlay(v)
            }

            // 轨迹
            if(GpsList.length>0){
                let arr=[]
                let arr2=[]
                GpsList.map((item,idx)=>{
                    let location1=WSCoordinate.transformFromWGSToGCJ(Number(item[1]),Number(item[0]));
                    let location2=WSCoordinate.transformFromGCJToBaidu(location1.latitude,location1.longitude);
                    let t = item[4].substring(0,19);    
                    t = t.replace(/-/g,'/'); //必须把日期'-'转为'/'
                    let timestamp = new Date(t).getTime();
                    timestamp = timestamp.toString().substr(0,10)
                    const item3 = {
                        "coord_type_input":"bd09ll",
                        "loc_time":timestamp,
                        "latitude":location2.latitude,
                        "longitude":location2.longitude
                    }                
                    arr.push(item3)
                    arr2.push({"latitude":location2.latitude,"longitude":location2.longitude})
                })
                // that.drawing(arr2,'red')
                that.rectifying(arr)
            }

            if (!that.timer) {
                that.timer = setInterval(() => {
                    that.getData()
                }, 30000)
            }
        },
        //纠偏
        rectifying(point_list){
            const that = this
            getBaiduMapTrackWithPCweb({PointList:JSON.stringify(point_list)}).then(res=>{
                if(res.data.code==1){
                    const data=res.data.data.points
                    if(data){
                        this.drawing(data,'#3a83f8')
                    }
                }
            }).catch(err=>{
                console.log('err',err)
            })
        },
        // 画线
        drawing(list,color){
            const that = this
            const arr=[]
            const len_1=Math.ceil(list.length*0.01)
            const len_2=Math.ceil(list.length/len_1)
            const points=[]
            var myIcon = new BMap.Icon('/imgs/arrow2.png', new BMap.Size(15, 15));  
            myIcon.setImageSize(new BMap.Size(15,15));//设置图标大小
            for(let i=1;i<len_1;i++){
                points.push(list[i*len_2])
                var point = new BMap.Point(list[i*len_2].longitude, list[i*len_2].latitude);  
                let marker = new BMap.Marker(point,{icon:myIcon});  
                marker.setRotation(list[i*len_2].direction);
                that.map.addOverlay(marker)
            }

            list.map(item=>{
                const item2=new BMap.Point(item.longitude,item.latitude)
                arr.push(item2)
            })
            if(arr.length==0)return;

            //创建矢量图标类
            const Symbol = new BMap.Symbol(BMap_Symbol_SHAPE_BACKWARD_OPEN_ARROW, {
                scale: 0.3,//图标缩放大小
                strokeWeight: 1,//设置线宽
                // rotation : 90, //图标旋转角度
                strokeColor : '#fff', //线填充颜色
                // strokeOpacity : 0, //线的透明度
                anchor: new BMap.Size(0, 0)
            });

            //设置折线样式符号显示
            const iconSequence = new BMap.IconSequence(Symbol, '30px', '30px',);
            //设置折线
            const polyline = new BMap.Polyline(arr,{
                strokeColor : color,
                strokeOpacity:1,
                strokeWeight : 8,
                enableMassClear:false,
                // icons : [iconSequence]
            });
            that.polyline=polyline
            that.map.addOverlay(polyline);
        },
    },
    computed:{
        
    },
    created(){
        const timestampStr = timestamp()
        const code = Encrypt(timestampStr)
        console.log("code",code)
    },
    mounted () {
        this.map = new BMap.Map('map')
        this.map.enableScrollWheelZoom();
        this.map.centerAndZoom(new BMap.Point(114.063977,22.55887), 16);
        const params=this.$route.query
        this.id=params.id
        this.db=params.db
        this.getData()
        this.code=params.code
        if(this.id && this.db && this.code){
            const timestampStr = timestamp()
            const code = Decrypt(this.code)
            const d = Math.abs(Number(timestampStr) - Number(code))
            if(d > 7200000){
                this.$router.push('/404')
                // location.href="/404"
            }
        }else{
            this.$router.push('/404')
            // location.href="/404"
        }
        
    },
    beforeDestroy(){
        this.timer=null
        clearInterval(this.timer)
    },
    watch:{},
}
</script>
<style lang="less" scoped>
    .container{
        height: 100vh;
        width: 100vw;
        overflow: hidden;
        #map{
            width:100vw; 
            height: 100vh; 
            position: absolute;
            top:0;
            left: 0;
            z-index: 1;
        } 
        .content{
            position: absolute;
            bottom:0;
            left: 0;
            z-index: 2;
            padding: 10px 20px;
            width: calc(100vw - 40px);
            background: #fff;
            box-shadow: 0 0 5px 0 grey;
            font-size: 14px;
            border-radius: 10px 10px 0px 0px;
            transition: all 0.3s ease;
            height: 150px;
            .content-top{
                .MonitorplanNo{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 16px;
                }
                >div{
                    display: flex;
                    margin:5px 0;
                    span{
                        color: #888888;
                    }
                    .type{
                        color: #3A83F8;
                        font-weight: bold;
                    }
                    img{
                        width: 20px;
                        height: 20px;
                        margin-right: 5px;
                    }
                }
                .arrow{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 auto 10px;
                    width: 50px;
                    width: 50px;
                    height: 24px;
                    background: #FFFFFF;
                    border: 1px solid rgba(58, 131, 248, 0.15);
                    border-radius: 12px;
                    box-shadow: 0 5px 10px 0 #e0e0e0;
                    img{
                        width: 17px;
                        height: 17px;
                        transition: all 0.3s ease;
                        margin: 0;
                    }
                }
            }
            .content-bot{   
                overflow-y: auto;
                height: 300px;
                >div{
                    margin-bottom: 10px;
                }
                .addr{
                    background: rgba(#BFBFBF,0.3);
                    padding: 0 10px;
                    display: flex;
                    justify-content: space-between;
                    height: 25px;
                    line-height: 25px;
                    font-size: 12px;
                    >div{
                        display: flex;
                        .palce{
                            width: 180px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
                .list{
                    margin:0 10px;
                    .item{
                        padding:5px 0;
                        display: flex;
                        align-items: center;
                        border-bottom: 1px solid#E5E5E5;
                        &:last-child{
                            border-bottom:none;
                        }
                        >div{
                            font-size:12px;
                            padding: 0 5px;
                            width: calc(30% - 10px);
                            &:first-child{
                                width: 10%;
                            }
                        }
                    }
                }
            }
        }
        .test{
            background: #fff;
            border-radius: 5px;
            padding-left:10px;
            overflow: hidden;
            position: absolute;
            top:0;
            right: 120px;
            z-index: 2;
            box-shadow: 0 0 5px 0 grey;
            span{
                margin-right: 10px;
                font-size: 14px;
            }
        }
        background: transparent;
    }
</style>